<template lang="html">
  <div>
    I am View Invoice
  </div>
</template>
<script>
export default {
  name: 'View'
}
</script>
